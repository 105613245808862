/* .champions .motto {
  max-width: 100%;
} */

.unique-width {
  max-width: 635px;
  margin: auto;
}

.motto-container {
  margin: 20px 0;
  text-align: center;
}

.motto-chinese {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.motto-english {
  font-size: 1.2em;
  font-style: italic;
  color: #666;
}